.footer-menu{
    display: grid;
    grid-template-columns: 2fr 1fr;
    
}
.footer-left-side{
    background: #F5FFE4;
    padding: 60px;
}
.footer-right-side{
    background: #D0F8FF;
    padding: 100px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    margin-left: -200px;
}
.icon-image img{
    width: 100%;
}
.content-product{
    font-size: 32px;
    font-weight: 700;
}
.content-product p{
    margin: 0;
}
.icon-image{
    display: grid;
    grid-template-columns: 220px 1fr;
    align-items: center;
    margin-bottom: 20px;
}
.date-info p{
    margin-top: 20px;
    font-weight: 400;
    font-size: 24px;
}
.copyright{
    font-weight: 700;
    font-size: 22px;
}
.right-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
    row-gap: 20px;
    color: #0A4EB4;
    font-weight: 700;
    font-size: 24px;
}
.have-account{
    font-weight: 700;
    font-size: 30px;
    color: #038096;
}
.support-email{
    font-weight: 700;
    color: #0A4EB4;
    font-size: 32px;
}
.social-icons {
    margin-right: 10px;
    display: grid;
    grid-template-columns: 50px 50px 1fr;
}

@media only screen and (min-width: 768px) {
    .footer-right-side{
        margin-left: 0;
        border-top-left-radius:0;   
        border-bottom-left-radius:0;
        padding: 40px;
    }
    .right-content{
        font-size: 20px;
    }
    .have-account{
        font-size: 20px;
    }
    .support-email{
        font-size: 20px;
    }
}
@media only screen and (max-width: 650px) {
    .footer-menu{
        display: grid;
        grid-template-columns: none;
    }
    .footer-right-side{
        margin-left: 0;
        border-top-left-radius:0;   
        border-bottom-left-radius:0;
        padding: 25px 25px 25px 40px;
    }
    .right-content{
        font-size: 20px;
    }
    .have-account{
        font-size: 20px;
    }
    .support-email{
        font-size: 20px;
    }
}
@media only screen and (max-width: 650px) {
    .footer-left-side{
        padding: 30px 15px 30px 15px;
    }
    .content-product{
        font-size: 15px;
    }
    .date-info {
        font-size: 15px;
    }
    .copyright{
        font-size: 15px;
    }
    .right-content{
        grid-template-columns: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .footer-menu{
        display: grid;
        grid-template-columns: none;
    }
    footer-left-side{
        padding: 40px;
    }
  }