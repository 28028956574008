.loader {display: grid;place-items: center;height: 100vh;}
/* .vcard-container {padding: 20px;} */
.vcard-header {box-shadow: 0 0 10px 0 #EEE;padding: 20px;}
.vcard-header img {width: 150px;}
.user-details {text-align: center;margin: 20px 0 10px 0;}
.user-details h1 {font-size: 30px;margin: 10px 0 0 0;font-weight: 600;}
.user-details h2 {font-size: 20px;margin-bottom: 0;font-weight: 400;}
.user-details .img-box {width: 150px;height: 150px;display: inline-block;}
.user-details .img-box img {max-width: 100%;max-height: 100%;}
.vcard-details p {border-top: 1px solid #DDD;margin-bottom: 0;padding: 10px 15px;color: #444;}
.vcard-details p:first-child {border-top: 0;}
.vcard-details p span {font-weight: 600;font-size: 14px;color: initial;padding-top: 3px;display: block;}
.social-media {display: grid;border-top: 1px solid #DDD;}
.social-media a {text-align: center;padding: 15px;font-size: 20px;border-right: 1px solid #DDD;}
.social-media a:last-child {border-right: 0;}