.term-condition-body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}
.term-conditon-content {
    padding: 30px;
    background: #fff;
    }
h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #444;
}
h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #555;
}
p {
    margin-bottom: 15px;
}
.accept-btn {
    display: block;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}
.accept-btn:hover {
    background-color: #45a049;
}
.data-heading{
    font-size: 24px;
}