.message-box {position: fixed;z-index: 9999;top: 10px;width: 500px;left: calc(50% - 250px);background: #FFF;border-radius: 0.3rem;display: grid;grid-template-columns: 100px auto;background-color: #DEEEFB;padding: 15px 0;box-shadow: 0 0 10px 0 #AAA;border: 1px solid #8FC2E8;}
.message-box > div:first-child {display: grid;justify-items: center;}
.message-box > div:first-child > span {background-color: #8FC2E8;border-radius: 3rem;padding: 20px;height: 70px;width: 70px;display: grid;place-items: center;font-size: 24px;}
.message-box > div:last-child {padding-right: 15px;}
.message-box > div:last-child > h5 {font-size: 22px;font-weight: 600;display: grid;grid-template-columns: auto auto;justify-content: space-between;border-bottom: 1px solid #AAA;margin-bottom: 5px;align-items: center;text-transform: capitalize;}
.message-box > div:last-child > h5.success {color: #64A67F;}
.message-box > div:last-child > h5.error {color: #F24073;}
.message-box > div:last-child > h5.warning {color: #F99C39;}
.message-box > div:last-child > h5 > img {cursor: pointer;width: 15px;margin-bottom: 5px;}
.message-box > div > p {font-weight: 500;margin-bottom: 0;color: #444;word-break: break-word;}
.message-box > p.success {color: #64A67F;}
.message-box > p.error {color: #F24073;}
.message-box > p.warning {color: #F99C39;}

@media only screen and (max-width: 510px) {
    .message-box {width: calc(100% - 40px);left: 20px;}
}
