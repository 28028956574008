#banner {background-image: url('./background.jpg');background-size: 100% 100%;padding: 150px 0 100px 0;}
.banner {display: grid;grid-template-columns: 1fr 400px;align-items: center;}
.banner > div:first-child {padding-right: 100px;}
.banner > div > h1 {font-size: 60px;color: #004367;font-weight: 600;}
.banner > div > p {font-size: 18px;color: #004367;}
.heading {font-size: 45px;color: #004367;font-weight: 600;margin-top: 100px;}
.contact-us {display: grid;grid-template-columns: 1fr 1fr;padding: 0 0 100px 0;gap: 30px;}
.contact-us > div > p {font-size: 18px;margin-top: 20px;}
.address-container {background-color: #ECFBFF;border-radius: 0.5rem;padding: 50px;text-align: center;margin-top: 100px;}
.address-heading {color: #004367;font-weight: 600;font-size: 35px;}
.address-box {display: grid;grid-template-columns: auto auto;}
.address {width: 400px;background-color: #FFF;border-radius: 0.5rem;padding: 30px 60px;font-weight: 500;margin-bottom: 0;margin-top: 30px;}
.address span {font-size: 25px;display: block;color: #004367;line-height: 1;margin-bottom: 10px;}
.data-field input{background-color: #FFFFFF;width: 100%;padding: 10px 15px;border-radius: 0.3rem;border: 1px solid #CCC;margin-bottom: 20px !important;}

@media only screen and (max-width: 992px) {
    .banner {grid-template-columns: 1fr 350px;}
    .banner > div:first-child {padding-right: 0;}
    .banner > div > h1 {font-size: 48px;}
    .contact-us {grid-template-columns: 350px 1fr;}
    .contact-us > div:last-child {padding-left: 50px;}
}

@media only screen and (max-width: 800px) {
    .banner {grid-template-columns: 1fr;}
    .banner > div > h1 {font-size: 40px;}
    .banner > div:last-child > img {width: 400px;margin-top: 20px;}
    .contact-us {grid-template-columns: 1fr;}
    .contact-us > div:last-child {padding-left: 0;}
    .contact-us > div:first-child > img {width: 400px;margin-bottom: 20px;}
}

@media only screen and (max-width: 1000px) {
    .address-box {display: block;}
    .address {width: auto;}
}

@media only screen and (max-width: 500px) {
    .address {padding: 30px;}
}

@media only screen and (max-width: 420px) {
    .banner > div:last-child > img {width: 100%;}
    .contact-us > div:first-child > img {width: 100%;}
}
