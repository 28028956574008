.NAVBAR{
  position: sticky;
  top: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f2f3f7;
  padding-inline: 100px;
  padding-block: 20px;
  position: relative;
}
.header .navbar {
  display: flex;
  gap: 55px;
  align-items: center;
}
.navbar a {
  text-decoration: none;
  color: #035c8b;
}
.navbar h5 {
  font-weight: 600;
  font-size: 25px;
  color: #035c8b;
  cursor: pointer;
  margin: 0;
}
.navbar h5:hover {
  color: #ef8706;
  transition: 0.3s;
}
.navbar button {
  background-color: #0377b6;
  padding-inline: 15px;
  padding-block: 3px;
  text-align: center;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-top: -5px;
}
.navbar button:hover {
  background-color: transparent;
  color: #ef8706;
  transition: 0.9s;
}
.dropdown {
  position: relative;
}
.maincontainer {
  width: 630px;
  height: 270px;
  position: absolute;
  top: 30px;
  right: -220px;
}
.maincontainer .mainvector {
  margin-top: 5px;
  padding: 0;
  display: flex;
  justify-content: center;
}
.subcontainer {
  display: flex;
  width: 100%;
  height: 260px;
  background-color: white;
  border-radius: 5px;
  border-top: 5px solid #035c8b;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.subcontainer .menudiv {
  width: 249px;
  height: 100%;
}
.menudiv .QRdiv {
  width: 100%;
  height: 129px;
  background-color: #cdd9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #035c8b;
  position: relative;
}
.menudiv .QRdiv1 {
  width: 100%;
  height: 129px;
  background-color: #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #035c8b;
  position: relative;
}
.menudiv .NFCdiv {
  width: 100%;
  height: 126px;
  background-color: #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #035c8b;
  border-bottom-left-radius: 5px;
  position: relative;
}
.menudiv .NFCdiv1 {
  width: 100%;
  height: 126px;
  background-color: #cdd9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #035c8b;
  border-bottom-left-radius: 5px;
  position: relative;
}
.menudiv .subdropdown {
  position: absolute;
  width: 10px;
  top: 45%;
  right: -10px;
}
.subcontainer .contentdiv {
  width: 100%;
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 21px;
  justify-content: space-between;
}
.contentdiv .services {
  width: 190px;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 15px;
  background-color: #f7f7f7;
  border-radius: 10px;
}
.contentdiv .services:hover {
  background-color: #dfdede;
  cursor: pointer;
  transition: 0.8s;
}
.services img {
  width: 40px;
}
.servicedetail h3 {
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  color: #004367;
}
.servicedetail p {
  font-size: 10px;
  margin: 0;
  color: #004367;
  font-weight: 500;
}
.nfccontent {
  width: 100%;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nfccontent img {
  width: 40%;
}
.nfccontent h3 {
  color: #004367;
  font-weight: 600;
}
.nfccontent p {
  font-size: 18px;
  font-weight: 600;
  color: #004367;
}
.hamburgermenu{
  display: none;
}
.mobilenavbar{
  background-color: #f2f3f7;
  position: fixed;
  right: -1000px;
  top: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 100;
  width: 60%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  transition: 0.5s;
  display: none;
}
.mobilenavbaractive{
  background-color: #f2f3f7;
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 100;
  width: 60%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  transition: 0.5s;
  display: none;
}
.mobilenavbar::-webkit-scrollbar{
  display: none;
}
.mobilenavbar .closeimg{
  width: 100%;
  padding-top: 15px;
  padding-inline: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.mobilenavbaractive .closeimg{
  width: 100%;
  padding-top: 15px;
  padding-inline: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.closeimg img{
  width: 25px;
  color: #004367;
}
.navlinks{
  padding-top: 20px;
  padding-bottom: 40px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.active{
  color: #ef8706;
}
.navlinks h5{
  color: #004367;
  text-decoration: none;
  font-size: 25px;
  font-weight: 600;
}
.navlinks button{
  background-color: #035c8b;
  color: white;
  font-size: 25px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: center;
}
.navlinks a{
  text-decoration: none;
}
.oursolution{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.oursolution h5{
  margin-bottom: 2px;
}
.oursolution img{
  width: 25px;
}
.servicesolution{
  padding-top: 15px;
  padding-left: 20px;
}
.servicesolutionheading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.servicesolutionheading h6{
  margin-bottom: 4px;
  font-weight: 600;
  color: #004367;
  font-size: 18px;
}
.servicesolutionheading .defaultimg{
  width: 23px;
  transform: rotate(89deg);
  transition: 0.3s;
}
.servicesolutionheading .activeimg{
  width: 23px;
  transform: rotate(0deg);
  transition: 0.3s;
}
.servicesolution .ourservices{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
  padding-left: 18px;
}
.ourservices .nfcimg{
  width: 70%;
  margin-left: 35px;
}
.ourservices h5{
  text-align: center;
  margin: 0;
}
.ourservices p{
  text-align: center;
  margin: 0;
  color: #004367;
}
.ourservices .service{
  display: flex;
  align-items: center;
  gap: 10px;
}
.service img{
  background-color: #ebebeb;
  padding-block: 5px;
  padding-inline: 5px;
  border-radius: 8px;
}
.service h6{
  margin: 0;
  font-weight: 600;
  color: #004367;
}
@media (max-width: 1150px) {
  .header {
    padding-inline: 50px;
  }
  .header .logo {
    width: 70%;
  }
  .header .navbar {
    display: flex;
    gap: 40px;
    align-items: center;
  }
  .navbar h5 {
    font-weight: 600;
    font-size: 20px;
    color: #035c8b;
    cursor: pointer;
  }
}
@media (max-width: 835px) {
  .header {
    padding-inline: 30px;
  }
  .header .logo {
    width: 60%;
  }
  .header .navbar {
    display: flex;
    gap: 40px;
    align-items: center;
  }
  .navbar h5 {
    font-weight: 600;
    font-size: 17px;
    color: #035c8b;
    cursor: pointer;
  }
  .navbar button {
    padding-inline: 10px;
    padding-block: 2px;
    font-size: 17px;
  }
}
@media (max-width: 735px) {
  .header {
    padding-inline: 20px;
  }
  .header .logo {
    width: 50%;
  }
  .header .navbar {
    gap: 30px;
  }
  .navbar h5 {
    font-size: 15px;
  }
  .navbar button {
    padding-inline: 10px;
    padding-block: 2px;
    font-size: 14px;
  }
  @media (max-width: 644px) {
    .header {
      padding-inline: 15px;
    }
    .header .logo {
      width: 45%;
    }
    .header .navbar {
      gap: 20px;
    }
    .navbar h5 {
      font-size: 13px;
    }
    .navbar button {
      padding-inline: 10px;
      padding-block: 2px;
      font-size: 12px;
    }
  }
  @media (max-width: 600px) {
    .header{
      padding-inline: 40px;
      padding-block: 10px;
      display: grid;
      grid-template-columns: auto auto;
    }
    .header .logo{
      width: 70%;
    }
    .navbar {
      background-color: #ef8706;
      flex-direction: column;
    }
    .hamburgermenu {
        display: block;
        font-size: 35px;
        font-weight: bold;
        color: #004367;
    }
    .mobilenavbar{
      display: block;
    }
    .mobilenavbaractive{
      display: block;
    }
  }
  @media (max-width: 400px){
    .navlinks h5{
      font-size: 20px;
    }
    .navlinks button{
      font-size: 20px;
    }
    .servicesolution{
      padding-top: 15px;
      padding-left: 10px;
    }
    .servicesolution .ourservices{
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-top: 15px;
      padding-left: 5px;
    }
    .ourservices .nfcimg{
      width: 70%;
      margin-left: 25px;
    }
    .ourservices h5{
      text-align: center;
      margin: 0;
    }
    .ourservices p{
      text-align: center;
      margin: 0;
      color: #004367;
      font-size: 16px;
    }
  }
}
