/* -------------------- Banner section starts here */
.homepage {
  background-color: #f2f3f7;
  font-size: 18px;
  font-weight: 500;
}
.banner {
  background-color: #fff;
}
.banner_text {
  text-align: center;
  padding: 120px 0;
}
.banner_text h1 {
  color: #ef8706;
  font-size: 30px;
  font-weight: 700;
}
.banner_text h2 {
  color: #004367;
  font-size: 45px;
  font-weight: 700;
  width: 650px;
  max-width: 100%;
  display: inline-block;
  margin-top: 30px;
}
.text_rotator {
  display: block;
  color: #ef8706;
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  opacity: 1; /* Fully visible by default */
}
.fade_out {
  opacity: 0;
}
.fade_in {
  opacity: 1;
}


/* -------------------- Banner section ends here */

/* -------------------- Personalized section starts here */
.personalized_container {
  padding: 50px 0;
}
.personalized_container img {
  width: 100%;
  max-width: 400px;
}
.personalized_box {
  background-color: #fff;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 100px;
  padding: 30px;
  border-radius: 0.5rem;
}
.personalized_heading {
  color: #004367;
  font-weight: 600;
  font-size: 35px;
}
.personalized_heading p {
  margin-bottom: 0;
}
.personalized_heading p span {
  color: #ef8706;
}
.personalized_gif {
  display: grid;
  height: 100%;
  place-items: center;
}
.products {
  border: 2px solid #5e93ff;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 20px 0;
  height: 500px;
}
.products > div {
  padding: 40px 60px;
  border-right: 20px solid transparent;
  cursor: pointer;
}
.selected_product {
  background-color: #e7f7ff;
  border-right: 20px solid #fd99ff !important;
}

@media only screen and (max-width: 991px) {
  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    height: auto;
  }
  .products > div {
    text-align: center;
    border-right: 0;
    border-bottom: 20px solid transparent;
    padding: 0;
    height: 130px;
    display: grid;
    place-items: center;
  }
  .products > div > svg {
    width: 50px;
  }
  .selected_product {
    border-right: 0 !important;
    border-bottom: 20px solid #fd99ff !important;
  }
  .personalized_box {
    grid-template-columns: 1fr;
  }
}
/* -------------------- Personalized section ends here */

/* -------------------- Specialization section starts here */
.our_specialization_container {
  background-color: #fff;
}
.our_specialization_container img {
  width: 100%;
  max-width: 400px;
}
.qr_specialization {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 50px;
  padding: 50px 0;
}
.nfc_specialization {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 50px;
  padding: 50px 0;
}

@media only screen and (max-width: 991px) {
  .qr_specialization {
    grid-template-columns: 1fr;
  }
  .nfc_specialization {
    grid-template-columns: 1fr;
  }
}
/* -------------------- Specialization section ends here */

/* -------------------- Product section starts here */
.our_products_heading {
  background-color: #f7fdff;
  border: 1px solid #e4e0e0;
  border-radius: 0.5rem;
  width: 900px;
  max-width: 100%;
  padding: 20px 140px;
  color: #004367;
  font-weight: 600;
  font-size: 30px;
  display: inline-block;
}
.our_products_heading p {
  margin-bottom: 0;
}
.our_products_heading p span {
  color: #ef8706;
}

.qr_product_container {
  padding: 50px 0;
}
.qr_product_box {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 150px 50px 50px 50px;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 50px;
  margin-top: -100px;
  font-size: 18px;
  font-weight: 500;
}
.qr_product_box img {
  width: 100%;
  max-width: 400px;
}
.qr_product_box ul {
  color: black;
  font-weight: 400;
  font-size: 16px;
}

.nfc_product_container {
  padding: 50px 0;
}
.nfc_product_box {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 50px;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 50px;
  font-size: 16px;
  font-weight: 500;
}
.nfc_product_box img {
  width: 100%;
  max-width: 400px;
}
.nfc_product_box ul {
  color: black;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {
  .our_products_heading {
    font-size: 22px;
  }
  .qr_product_box {
    grid-template-columns: 1fr;
  }
  .nfc_product_box {
    grid-template-columns: 1fr;
  }
}
/* -------------------- Product section ends here */

/* -------------------- Company trust section starts here */
.company_trust_container {
  background-color: #fff;
}
.company_trust_box {
  text-align: center;
  padding-bottom: 30px;
}
.company_trust_heading {
  background-color: #f7fdff;
  border: 1px solid #e4e0e0;
  border-top: 0px;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 600px;
  max-width: 100%;
  padding: 20px;
  color: #004367;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  margin-bottom: 50px;
}
.company_trust_heading p {
  margin-bottom: 0;
}
.company_trust_heading p span {
  color: #ef8706;
}
.company_trust {
  border: 1px solid #cac9c9;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, auto);
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
}
.company_trust img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .company_trust {
    grid-template-columns: repeat(2, auto);
  }
}
/* -------------------- Company trust section ends here */

/* -------------------- Testimonial section starts here */
.testimonials_container {
  padding: 60px 0;
}
.testimonials_box {
  background-color: #fff;
  padding: 0 40px 100px 40px;
  border-radius: 1rem;
  text-align: center;
}
.testimonial_heading {
  background-color: #f7fdff;
  border: 1px solid #e4e0e0;
  border-top: 0px;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 600px;
  max-width: 100%;
  padding: 20px 20px 0 20px;
  color: #004367;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  margin-bottom: 50px;
}
.testimonial_heading p {
  margin-bottom: 0;
}
.pink_dot {
  background-color: #fda1ff;
  border-radius: 2rem;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 0 -30px 0;
}
.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.testimonial {
  border: 1px solid #eee;
  border-radius: 0.3rem;
  padding: 20px;
  color: #004367;
  font-weight: 500;
}
.testimonial:hover {
  background-color: #f7fdff;
}
.stars {
  color: #ffc727;
  font-size: 20px;
}
.testimonial p.testimonial_text {
  font-size: 18px;
  margin: 10px 0 20px 0;
}
.testimonial p.testimonial_name {
  margin: 5px 0 0 0;
}
.testimonial span {
  font-size: 14px;
}
.testimonial_oval {
  float: right;
  width: 8vw;
  height: 8vw;
  border-radius: 5rem;
  margin-top: -4vw;
  margin-right: -4vw;
  margin-left: -4vw;
  background-color: #ffbab6;
}
.testimonial_background {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-top: -250px;
}
.testimonial_background img {
  height: 20vw;
}

@media only screen and (max-width: 991px) {
  .testimonial_heading {
    font-size: 22px;
  }
  .testimonials {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .company_trust {
    grid-template-columns: repeat(4, auto);
  padding: 15px;
  }
  .qr_product_box {
    padding: 150px 15px 15px 15px;
  }
  .nfc_product_box {
    padding: 30px 15px 15px 15px;
  }
  .banner_text {
    padding: 20px;
  }
  .personalized_heading {
    font-size: 28px;
    margin-top: 20px;
  }
  .banner_text h2 {
    font-size: 29px;
  }
}

@media only screen and (max-width: 650px) {
  .our_products_heading {
    padding: 20px;
   }
   .personalized_heading {
    font-size: 20px;
  }
  .banner_text {
    padding: 30px;
  }
  .banner_text h2 {
    font-size: 22px;
  }
  .personalized_heading p {
    margin-top: 20px;
  }
  .qr_product_box {
    padding: 130px 15px 15px 15px;
    line-height: 1.7;
  }
  .nfc_product_box {
    padding: 15px;
  }
  .company_trust {
    grid-template-columns: repeat(4, auto);
    display: grid;
    padding: 15px;
  }
  .qr_product_box ul {
    font-weight: 500;
  }
}
/* -------------------- Testimonial section ends here */

