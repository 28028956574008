cancellation-body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}
.cancelllation-content {
    padding: 30px;
    background: #fff;
}
.cancelllation-content p{
    font-weight: 500;
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #444;
}
h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #555;
}
p {
    margin-bottom: 15px;
}
.data-heading{
    font-size: 24px;
    font-weight: 500;
}